import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "settings";

    return instance.get(url);
  },
  guardar(data, image1, image2, image3, image4, image5, image6, image7, image8) {
    var headers = {
      'Content-Type': 'multipart/form-data'
    }

    var formData = new FormData();

    data = JSON.parse(data)
    for ( var key in  data) {
      if(key!="logo" && key!="favicon" && key!="imageLogin")
      formData.append(key, data[key]);

      if(key=="logo") {
        var i = 0
        image1.forEach((value, key) => {
          formData.append('logo_' + i, value);
          i++
        })
        formData.append('logo', i);
      }

      if(key=="favicon") {
        var i = 0
        image2.forEach((value, key) => {
          formData.append('favicon_' + i, value);
          i++
        })
        formData.append('favicon', i);
      }

      if(key=="imageLogin") {
        var i = 0
        image3.forEach((value, key) => {
          formData.append('imageLogin_' + i, value);
          i++
        })
        formData.append('imageLogin', i);
      }

      if(key=="pwaIcon192") {
        var i = 0
        image4.forEach((value, key) => {
          formData.append('pwaIcon192_' + i, value);
          i++
        })
        formData.append('pwaIcon192', i);
      }

      if(key=="pwaIcon256") {
        var i = 0
        image5.forEach((value, key) => {
          formData.append('pwaIcon256_' + i, value);
          i++
        })
        formData.append('pwaIcon256', i);
      }  
      
      if(key=="pwaIcon384") {
        var i = 0
        image6.forEach((value, key) => {
          formData.append('pwaIcon384_' + i, value);
          i++
        })
        formData.append('pwaIcon384', i);
      }       

      if(key=="pwaIcon512") {
        var i = 0
        image7.forEach((value, key) => {
          formData.append('pwaIcon512_' + i, value);
          i++
        })
        formData.append('pwaIcon512', i);
      }      
      
      if(key=="imageWatermark") {
        var i = 0
        image8.forEach((value, key) => {
          formData.append('imageWatermark_' + i, value);
          i++
        })
        formData.append('imageWatermark', i);
      }      
    }

    var params = formData

    var url = ConfigAPI.baseURL + "settings";

    return instance.post(url,params,{headers});
  },
}

export default servicesAPI;
